/* eslint-disable @typescript-eslint/no-explicit-any */
import { Timestamp } from '@angular/fire/firestore';
import { format, millisecondsToMinutes } from 'date-fns';
import { CalendarItem } from '../components/calendar/calendar.component';
import { Schedule, ScheduleTime } from '../models/schedule.model';
import { Site } from '../models/site.model';
import { User } from '../models/user.model';
import { BasicTicket } from '../services/worker-tickets.service';
import { isTimestamp } from './time';

export const isUser = (object: any): object is User => {
  return 'guid' in object && 'displayName' in object;
};

export const isBasicTicket = (object: any): object is BasicTicket => {
  return 'site' in object;
};

export const hasHeader = (object: any): object is { header: string } => {
  return 'header' in object;
};

function getDate(date: Timestamp | Date, time: ScheduleTime, field: string) {
  const itemDate = isTimestamp(date) ? date.toDate() : date;
  const itemTime = isTimestamp(time[field]) ? time[field].toDate() : time[field];
  itemDate.setHours(itemTime.getHours());
  itemDate.setMinutes(itemTime.getMinutes());
  return itemDate;
}

export function getDateFromTimestamp(date: Timestamp | Date) {
  if (date == null) {
    return null;
  }
  const itemDate = isTimestamp(date) ? date.toDate() : date;
  return itemDate;
}

export function getCalendarItem(item: Schedule, userGuid: string, sites: Site[]) {
  const time = item.times.find((itt) => itt.user === userGuid);

  const startDate = getDate(item.dateObj, time, 'start');
  const endDate = getDate(item.dateObj, time, 'end');
  const duration = millisecondsToMinutes(endDate.getTime() - startDate.getTime());
  const name = sites.find((s) => s.guid === item.site)?.name;
  const information = item.information?.find((itt) => itt.user === userGuid)?.text;
  const subtitle = `${format(startDate, 'HH:mm')} - ${format(endDate, 'HH:mm')}`;

  const calendarItem: CalendarItem<Schedule> = {
    guid: item.guid,
    name,
    duration,
    startDate,
    endDate,
    information,
    subtitle,
    item: Object.assign({}, item),
  };
  return calendarItem;
}
